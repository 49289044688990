exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-index-js": () => import("./../../../src/pages/about-us/index.js" /* webpackChunkName: "component---src-pages-about-us-index-js" */),
  "component---src-pages-blogs-fabric-care-index-jsx": () => import("./../../../src/pages/blogs/fabric-care/index.jsx" /* webpackChunkName: "component---src-pages-blogs-fabric-care-index-jsx" */),
  "component---src-pages-blogs-index-js": () => import("./../../../src/pages/blogs/index.js" /* webpackChunkName: "component---src-pages-blogs-index-js" */),
  "component---src-pages-blogs-laundry-index-jsx": () => import("./../../../src/pages/blogs/laundry/index.jsx" /* webpackChunkName: "component---src-pages-blogs-laundry-index-jsx" */),
  "component---src-pages-blogs-laundry-tips-index-jsx": () => import("./../../../src/pages/blogs/laundry-tips/index.jsx" /* webpackChunkName: "component---src-pages-blogs-laundry-tips-index-jsx" */),
  "component---src-pages-blogs-lifestyle-index-jsx": () => import("./../../../src/pages/blogs/lifestyle/index.jsx" /* webpackChunkName: "component---src-pages-blogs-lifestyle-index-jsx" */),
  "component---src-pages-blogs-product-spotlight-index-jsx": () => import("./../../../src/pages/blogs/product-spotlight/index.jsx" /* webpackChunkName: "component---src-pages-blogs-product-spotlight-index-jsx" */),
  "component---src-pages-contact-us-index-js": () => import("./../../../src/pages/contact-us/index.js" /* webpackChunkName: "component---src-pages-contact-us-index-js" */),
  "component---src-pages-customer-login-index-js": () => import("./../../../src/pages/customer/login/index.js" /* webpackChunkName: "component---src-pages-customer-login-index-js" */),
  "component---src-pages-customer-signup-index-js": () => import("./../../../src/pages/customer/signup/index.js" /* webpackChunkName: "component---src-pages-customer-signup-index-js" */),
  "component---src-pages-faqs-index-js": () => import("./../../../src/pages/faqs/index.js" /* webpackChunkName: "component---src-pages-faqs-index-js" */),
  "component---src-pages-forgot-password-index-jsx": () => import("./../../../src/pages/forgot-password/index.jsx" /* webpackChunkName: "component---src-pages-forgot-password-index-jsx" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacy-policy-index-jsx": () => import("./../../../src/pages/privacy-policy/index.jsx" /* webpackChunkName: "component---src-pages-privacy-policy-index-jsx" */),
  "component---src-pages-supplies-index-jsx": () => import("./../../../src/pages/supplies/index.jsx" /* webpackChunkName: "component---src-pages-supplies-index-jsx" */),
  "component---src-pages-terms-and-conditions-index-js": () => import("./../../../src/pages/terms-and-conditions/index.js" /* webpackChunkName: "component---src-pages-terms-and-conditions-index-js" */),
  "component---src-pages-unsubscribe-index-jsx": () => import("./../../../src/pages/unsubscribe/index.jsx" /* webpackChunkName: "component---src-pages-unsubscribe-index-jsx" */),
  "component---src-pages-washer-app-jsx": () => import("./../../../src/pages/washer/app/[...].jsx" /* webpackChunkName: "component---src-pages-washer-app-jsx" */),
  "component---src-pages-washer-index-js": () => import("./../../../src/pages/washer/index.js" /* webpackChunkName: "component---src-pages-washer-index-js" */),
  "component---src-pages-washer-login-index-jsx": () => import("./../../../src/pages/washer-login/index.jsx" /* webpackChunkName: "component---src-pages-washer-login-index-jsx" */),
  "component---src-pages-washer-registration-index-jsx": () => import("./../../../src/pages/washer-registration/index.jsx" /* webpackChunkName: "component---src-pages-washer-registration-index-jsx" */),
  "component---src-templates-blog-post-jsx": () => import("./../../../src/templates/blogPost.jsx" /* webpackChunkName: "component---src-templates-blog-post-jsx" */)
}

