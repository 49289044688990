import { createSlice } from "@reduxjs/toolkit";

const initialWasherState = {
  washer: null,
  isEmailVerified: false,
  request: null,
};

const washerSlice = createSlice({
  name: "washer",
  initialState: initialWasherState,
  reducers: {
    setWasher(state, action) {
      state.washer = action.payload;
    },
    setIsEmailVerified(state, action) {
      state.isEmailVerified = action.payload;
    },
    setRequest(state, action) {
      state.request = action.payload;
    },
  },
});

export const washerActions = washerSlice.actions;
export default washerSlice.reducer;
