import { configureStore } from "@reduxjs/toolkit";
import washerSlice from "./washer/washer-slice";
import loaderSlice from "./loader/loader-slice";
import modalSlice from "./modal/modal-slice";

const store = configureStore({
  reducer: {
    washer: washerSlice,
    loader: loaderSlice,
    modal: modalSlice,
  },
});

export default store;
