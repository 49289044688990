import { createSlice } from "@reduxjs/toolkit";

const initialLoaderState = {
  loading: false,
  text: "loading...",
};

const loaderSlice = createSlice({
  name: "loader",
  initialState: initialLoaderState,
  reducers: {
    setLoader(state, action) {
      state.loading = action.payload.loading;
      state.text = action.payload.text;
    },
  },
});

export const loaderActions = loaderSlice.actions;
export default loaderSlice.reducer;
