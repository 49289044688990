// gatsby-browser.js
import "@fontsource/outfit/400.css"; // Regular weight
import "@fontsource/outfit/500.css"; // Medium weight
import "@fontsource/outfit/600.css"; // Semi-Bold weight
import "@fontsource/outfit/700.css"; // Bold weight

import "firebase/auth";
import "firebase/firestore";
import Modal from "react-modal";

import wrapWithProvider from "./wrap-with-provider";
import browserHydrateFunction from "./src/utils/browser-hydrate-functions";
export const wrapRootElement = wrapWithProvider;

export const onClientEntry = () => {
  Modal.setAppElement("#___gatsby");
};

export const replaceHydrateFunction = browserHydrateFunction;
